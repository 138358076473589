import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Link,
  //    NavLink, useHistory, useLocation
} from "react-router-dom";
//import Swal from "sweetalert2";
import "../css/landing.css";
import { Collapse } from "react-bootstrap";
//import Rightcontainer from "../Components/Rightcontainer";

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  

import Downloadbutton from "../Components/Downloadbutton";
import Header from "../Components/Header";
import { UserFooterBar } from '../Components/UserFooterBar';


export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          //window.location.href = "/login";
          //window.location.reload()
          //history.push("/login")
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      //window.location.reload()
    }
    role();
    fetchData();
  }, []);

  return (
    <>
      <Header user={userAllData} />
      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "60px" }}>
        
        {/*
           <div className="gameCard mr-3 ml-3 mt-1" style={{marginBottom: '5px'}}>
            <div className="collapseCard">
                <div
                  className="collapseCard-body bg-danger"
                  style={{ height: "60px", opacity: 1 }}
                >
                  <div className=" collapseCard-text ">
                    {" "}
                    <strong className=" text-white">
                    ◉ तकनीकी समस्याओं के कारण वेबसाइट का रखरखाव चल रहा है। कृपया धैर्य रखें। अगले 24 घंटे में वेबसाइट दोबारा शुरू हो जाएगी
                    </strong>
                  </div>
                </div>
            </div>
          </div> 
        */}
            
            
          {/*<div className="mr-3 ml-3 mt-1">
                <OwlCarousel items={1}  
                    className="owl-theme"  
                    loop 
                    nav ={false}
                    dots ={false}  
                        autoplay={true}
                        autoplayTimeout={2000}
                        autoplayHoverPause={true}
                    >
                    
                    <div className="home_slider">
                    <img  className="img" src= {'/Images/logo/3refer.png'}/>
                    </div>
                   
                </OwlCarousel>  
            </div> */}
            
            
             { WebSitesettings.CompanyMsgFront &&
          <div className="gameCard mr-3 ml-3 mt-1" style={{marginTop: '60px'}}>
            <div className="collapseCard">
                <div
                  className="collapseCard-body bg-danger"
                  style={{ height: "60px", opacity: 1 }}
                >
                  <div className=" collapseCard-text ">
                    {" "}
                    <strong className=" text-white">
                      ◉ {WebSitesettings.CompanyMsgFront}
                    </strong>
                  </div>
                </div>
            </div>
          </div> 
          }
            
       

          
          <section className="games-section p-3">
            <div className="d-flex align-items-center games-section-title">
              Our Tournaments
            </div>
            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window ">
              
                <Link
                  className="gameCard-container"
                  width={"18rem"}
                  to={`/Homepage/LudoManual`}
                >
                  <span className="d-none blink text-danger d-block text-right">
                    ◉ LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/logo/ludoclassic.png"
                      }
                      alt=""
                    />
                  </picture>
                  
                  {/*<div className="gameCard-title">
                    <span className="d-none blink text-success  d-block text-right">
                      ◉ LUDO CLASSICS MANUAL
                    </span>
                  </div>
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>*/}
                  
                </Link>
                
                {/* <Link
                  className="gameCard-container"
                // Ludo%20Classics
                  to={`/`}
                >
                  <span className="d-none blink text-danger d-block text-right">
                    ◉ Comming Soon
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/logo/jaipurludo.png"
                      }
                      alt=""
                    />
                  </picture>
                  
                  <div className="gameCard-title">
                    <span className="d-none blink text-success d-block text-right">
                      ◉ LUDO CLASSICS AUTO
                    </span>
                  </div>
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>
                  
                </Link> 

                <Link
                  className="gameCard-container"
                  to={`/Homepage/snakgame`}
                >
                  <span className="d-none blink text-danger d-block text-right">
                    ◉ LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL + "/Images/logo/snakejp.png"
                      }
                      alt="sdf"
                    />
                  </picture>
                
                </Link> */}

               {/* <a href='#' className="gameCard-container" onClick={() => window.tidioChatApi.open()}>*/}
                <a href='https://api.whatsapp.com/send?phone=+917425889515&text=Hello' className="gameCard-container">
                  <span className="d-none blink text-danger d-block text-right">
                    ◉ LIVE
                  </span>
                  <picture className="gameCard-image">
                    <img
                      width="100%"
                      src={process.env.PUBLIC_URL + "/Images/logo/support.png"}
                      alt=""
                    />
                  </picture>
                  
                  {/*<div className="gameCard-title">
                    <span className="d-none blink text-success  d-block text-right">
                      ◉ JaipurLudo Support
                    </span>
                  </div>
                  <div className="goverlay">
                    <div className="text">Comming Soon</div>
                  </div>*/}
                </a>

              
              </div>
            </div>
          </section>
          
        
          
         

         
          <div className="downloadButton">
            <Downloadbutton />
          </div>
          
        <div id="userFooterBar">
            <UserFooterBar />
          </div>
          
        </div>
      </div>
      {/* // <div className='rightContainer'>
            //     <Rightcontainer/>
            // </div> */}
    </>
  );
}
